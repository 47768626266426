import React, { useEffect, useRef, useState } from "react";
import GithubLogo from "../../assets/images/github-logo.svg";
import InstaLogo from "../../assets/images/insta-logo.svg";
import LinkedInLogo from "../../assets/images/linkedin-logo.svg";
import Calendly from "../../components/Calendly";

const MainBanner = () => {
  const myRef = useRef();
  const [myElementIsVisible, setMyElementIsVisible] = useState(false);

  const followProfiles = [
    {
      img: LinkedInLogo,
      link: "https://www.linkedin.com/in/ashish-subedi-a6496a170/",
    },
    {
      img: GithubLogo,
      link: "https://github.com/Scorpi35/",
    },
    {
      img: InstaLogo,
      link: "https://www.instagram.com/ashishsubedi35/",
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setMyElementIsVisible(entry.isIntersecting);
    });

    observer.observe(myRef.current);
  }, []);

  return (
    <div ref={myRef}>
      <div className="stars" />
      <div className="stars1" />
      <div
        className={
          "container base-container main-banner-container" +
          (myElementIsVisible ? " main-banner-showup" : " opacity-0")
        }
      >
        <p className="fs-17">
          <highlight-text>Hi, my name is</highlight-text>
        </p>
        <br />
        <p className="fs-80 lh-sm fw-bold">
          <highlight-text-2>Ashish Subedi</highlight-text-2>
          <br />
          <div className="line-up fs-70">
            <highlight-text-3>I build AI powered Web Products</highlight-text-3>
          </div>
        </p>
        <br />
        <p
          className={"fs-17" + (myElementIsVisible ? " line-up" : " opacity-0")}
          style={{ width: "400px", maxWidth: "100%" }}
        >
          <highlight-text-3>
            And I’m a{" "}
            <highlight-text>
            Freelance Software Engineer.<br/><br/>
            </highlight-text>
            Clients have trusted me to help them build the products they want. Know more <a href="#about-me"><highlight-text-2><u>About Me</u></highlight-text-2></a> & <a href="#work"><highlight-text-2><u>My Work</u></highlight-text-2></a>.
          </highlight-text-3>
        </p>
        <br />

        <div
          className={
            "mb-5 follow-icon-container" +
            (myElementIsVisible ? " line-up" : " opacity-0")
          }
        >
          {followProfiles.map((followProfile, index) => {
            return (
              <div
                className={
                  "p-1 figure rounded-circle cursor-pointer" +
                  (index ? " ml-1" : "")
                }
              >
                <img
                  alt="Follow Me"
                  src={followProfile.img}
                  className="rounded-circle"
                  onClick={() => {
                    window.open(followProfile.link, "_blank", "noreferrer");
                  }}
                />
              </div>
            );
          })}
        </div>

        <div className="d-flex">
          <Calendly className="custom-primary__btn"/>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
