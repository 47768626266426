import React, { useState } from "react";
import Calendly from "../Calendly";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="w-100 navbar-container position-fixed">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <a className="navbar-brand" href="/#">
            <div className="navbar-logo d-flex justify-content-center align-items-center">
              A
            </div>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          >
            <span
              className={
                "navbar-dark navbar-toggler-icon" +
                (showMenu ? " btn-close btn-close-white" : "")
              }
            ></span>
          </button>
          <div
            className={(showMenu ? "" : "collapse ") + "navbar-collapse"}
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav">
              <li className="nav-item active">
                <a
                  className="nav-link"
                  href="#about-me"
                  onClick={() => {
                    setShowMenu(false);
                  }}
                >
                  About
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#work"
                  onClick={() => {
                    setShowMenu(false);
                  }}
                >
                  Work
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#blogs"
                  onClick={() => {
                    setShowMenu(false);
                  }}
                >
                  Blogs
                </a>
              </li>
              <li className="nav-item d-flex align-items-center cursor-pointer mx-3">
                <Calendly className="custom-primary__btn header-contact__btn"/>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
