import * as actionTypes from "../actions/action-types";

export const initialState = {
  aboutObserverState: false,
  workObserverState: false
}

const updateState = (name, state, payload) => {
  return Object.assign({}, state, {
    [name]: payload
  })
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ABOUT_OBSERVER_STATE:
      return updateState("aboutObserverState", state, action.payload);
    case actionTypes.SET_WORK_OBSERVER_STATE:
      return updateState("workObserverState", state, action.payload);
    default:
      return state;
  }
}

