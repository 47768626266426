import React, { useEffect, useRef, useState } from "react";
import AboutMePic from "../../assets/images/about-me-pic.webp";
import ReactLogo from "../../assets/images/react-logo.svg";
import RailsLogo from "../../assets/images/rails-logo.svg";
import AwsLogo from "../../assets/images/aws-logo.svg";
import PostgresLogo from "../../assets/images/postgres-logo.svg";

const AboutMe = () => {
  const myRef = useRef();
  const [myElementIsVisible, setMyElementIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setMyElementIsVisible(entry.isIntersecting);
    });

    observer.observe(myRef.current);
  }, []);

  return (
    <div className="container base-container about-me-container" id="about-me">
      <div className="stars" />
      <div className="stars1" />
      <div className="d-flex align-items-center about-me-header fs-30 fw-semibold">
        <div className="mw-fit-content">Being a Software Engineer...</div>
        <div className="m-2 mw-fit-content">
          <div className="header-line" />
        </div>
      </div>
      <div className="about-me-body d-flex row mt-5" ref={myRef}>
        <>
          <div
            className={
              "about-me-description col fs-17 typewriter " +
              (myElementIsVisible ? "show-up" : "opacity-0")
            }
            id="about-me-description"
          >
            <div className="show-up">
              <highlight-text-3>
                <p>
                  is more than just{" "}
                  <highlight-text>Software Engineering. </highlight-text> It's
                  more about in-depth understanding of the market you're
                  in.
                </p>

                <p>
                  You basically have to be a{" "}
                  <highlight-text>Product Builder.</highlight-text>
                </p>

                <p className="mt-4">
                  <highlight-text-2>My GoTo Stack</highlight-text-2>
                  <div className="mt-1">
                    <img
                      alt="react"
                      src={ReactLogo}
                      style={{ height: "30px", width: "30px" }}
                    />
                    <img
                      alt="rails"
                      className="mx-3"
                      src={RailsLogo}
                      style={{ height: "50px", width: "50px" }}
                    />
                    <img
                      alt="rails"
                      className="mx-3"
                      src={AwsLogo}
                      style={{ height: "50px", width: "50px" }}
                    />
                    <img
                      alt="rails"
                      className="mx-3"
                      src={PostgresLogo}
                      style={{ height: "35px", width: "35px" }}
                    />
                  </div>
                </p>

                <p className="mt-3 mb-6">
                  And I use these to build{" "}
                  <highlight-text-2>AI powered Web Products</highlight-text-2>
                </p>
                <a
                  className={
                    "mt-5 custom-primary__btn cursor-pointer text-decoration-none" +
                    (myElementIsVisible ? " line-up" : " opacity-0")
                  }
                  onClick={() => {}}
                  href="#work"
                >
                  See My Work History
                </a>
              </highlight-text-3>
            </div>
          </div>
          <div
            className={
              "about-me-photo col d-flex justify-content-center " +
              (myElementIsVisible ? "show-up" : "opacity-0")
            }
          >
            <div className="photo-container">
              <div className="photo-box position-relative">
                <img alt="Ashish Subedi" src={AboutMePic} />
              </div>
            </div>
          </div>
        </>
        {/* )} */}
      </div>
    </div>
  );
};

export default AboutMe;
