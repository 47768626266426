import React from "react";

const Footer = () => {
  return (
    <div className="w-100 footer-container position-fixed  d-flex justify-content-end">
      <div>
        <highlight-text-3>Designed & Developed by Ashish Subedi</highlight-text-3>
      </div>
    </div>
  );
};

export default Footer;
