import parse from "html-react-parser";
import React, { useEffect, useRef, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import AccountStory from "../../assets/files/JobDescriptions/AccountStory.txt";
import ComplianceQuest from "../../assets/files/JobDescriptions/ComplianceQuest.txt";
import CodnivInnovations from "../../assets/files/JobDescriptions/CodnivInnovations.txt";

const Work = () => {
  const [workHistory, setWorkHistory] = useState({});
  const [myElementIsVisible, setMyElementIsVisible] = useState(false);

  const myRef = useRef();

  const readJobDescription = async (company) => {
    try {
      const response = await fetch(company);
      const content = await response.text();
      return content;
    } catch (error) {
      console.error("Error loading file content:", error);
    }
  };

  useEffect(() => {

    let workHistoryObject = {
      AccountStory: {
        company_name: "Account Story",
        job_title: "Software Engineer",
        from_to_date: "Jan 2021 - Present (Remote)",
        company_url: "https://accountstory.com",
        job_file: AccountStory,
        job_description: null,
      },
      CodnivInnovations: {
        company_name: "Codniv Innovations",
        job_title: "Software Engineer",
        from_to_date: "Jun 2019 - Dec 2020",
        company_url: "https://codniv.com/",
        job_file: CodnivInnovations,
        job_description: null      
      },
      ComplianceQuest: {
        company_name: "Compliance Quest",
        job_title: "Software Engineering Intern",
        from_to_date: "Jun 2019 - Aug 2019",
        company_url: "https://www.compliancequest.com/compliancequest-asia/",
        job_file: ComplianceQuest,
        job_description: null      
      }
    };
    

    Object.keys(workHistoryObject).forEach(async (company_key) => {
      await readJobDescription(workHistoryObject[company_key].job_file).then((res) => {
        workHistoryObject = {
          ...workHistoryObject,
          [company_key]: {
            ...workHistoryObject[company_key],
            job_description: res,
          },
        };

        setWorkHistory(workHistoryObject);
        return;
      });
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setMyElementIsVisible(entry.isIntersecting);
    });

    observer.observe(myRef.current);
  }, []);


  return (
    <div className="container base-container work-history-container" id="work">
      <div className="stars" />
      <div className="stars1" />
      <div className="d-flex align-items-center work-history-header fs-30 fw-semibold">
        <div className="mw-fit-content">Work History</div>
        <div className="m-2 mw-fit-content">
          <div className="header-line" />
        </div>
      </div>
      <div className="work-history-details" ref={myRef}>
        {myElementIsVisible && (
          <Tabs>
            <TabList>
              {Object.keys(workHistory).map((company_key) => {
                return <Tab>{workHistory[company_key].company_name}</Tab>;
              })}
            </TabList>

            {Object.keys(workHistory).map((company_key) => {
              return (
                <TabPanel>
                  <div className="panel-content">
                    <div className="panel-content-header">
                      <header-title>
                        {workHistory[company_key].job_title}
                        <highlight-text
                          style={{ cursor: "pointer" }}
                          className="cursor-pointer"
                          onClick={() => {
                            window.open(
                              workHistory[company_key].company_url,
                              "_blank",
                              "noreferrer"
                            );
                          }}
                        >
                           {" "}
                          <u>@ {company_key}</u>
                        </highlight-text>
                      </header-title>
                      <br />
                      <header-date>
                        {workHistory[company_key].from_to_date}
                      </header-date>
                    </div>
                    <br />
                    <div className="panel-content-desc">
                      {workHistory[company_key].job_description &&
                        parse(workHistory[company_key].job_description)}
                    </div>
                  </div>
                </TabPanel>
              );
            })}
          </Tabs>
        )}
      </div>
    </div>
  );
};

export default Work;
