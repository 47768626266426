import React from "react";
import EmailIcon from "../../assets/images/email-icon.svg";
import LocationIcon from "../../assets/images/location-icon.svg";
import CheckMarkIcon from "../../assets/images/checkmark.svg";
import Calendly from "../../components/Calendly";

const GetInTouch = () => {

  return (
    <div
      className="container base-container get-in-touch-container"
      id="contact"
    >
      <div className="stars" />
      <div className="stars1" />
      <div className="get-in-touch-header d-flex justify-content-center">
        <p>
          <center>
            <header className="fs-50">Get In Touch</header>
          </center>
          <sub-header className="fs-20">
            <highlight-text-3>
              <center>
                I guess, you have something to discuss and I'm willing talk.
                <br />
                Together, we can embark on a new journey.
              </center>
            </highlight-text-3>
          </sub-header>
        </p>
      </div>
      <div className="key-services row mt-4">
        <div className="col">
          <p className="description">
            As a <highlight-text-2>Remote Software Engineer</highlight-text-2>,
            I've developed proficiency in addressing the{" "}
            <highlight-text>diverse range of responsibilities</highlight-text>{" "}
            inherent to the role.
          </p>
          <p style={{ color: "white", fontSize: "15px" }}>
            <div className="d-flex align-items-center">
              <img
                src={CheckMarkIcon}
                style={{ height: "15px", width: "15px" }}
                alt=""
              />{" "}
              &nbsp; Work Time Flexibility
            </div>
            <div className="d-flex align-items-center mt-2">
              <img
                src={CheckMarkIcon}
                style={{ height: "15px", width: "15px" }}
                alt=""
              />{" "}
              &nbsp; Readjustments of Plans
            </div>
            <div className="d-flex align-items-center mt-2">
              <img
                src={CheckMarkIcon}
                style={{ height: "15px", width: "15px" }}
                alt=""
              />{" "}
              &nbsp; Update Log
            </div>
          </p>

          <Calendly className="custom-primary__btn header-contact__btn mt-5" />
        </div>
        <div className="col d-flex justify-content-center info-container">
          <div>
            <div className="row">
              <div className="col" style={{ maxWidth: "40px" }}>
                <img src={EmailIcon} alt="Email" />
              </div>
              <div className="col mw-fit-content">
                <highlight-text-3>subediashish35@gmail.com</highlight-text-3>
              </div>
            </div>
            <div className="row mt-1">
              <div className="col" style={{ maxWidth: "40px" }}>
                <img src={LocationIcon} alt="Location" />
              </div>
              <div className="col mw-fit-content">
                <highlight-text-3>Kathmandu, Nepal</highlight-text-3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
