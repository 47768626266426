import React from "react";
import { PopupButton } from "react-calendly";

const Calendly = ({className}) => {
  return (
    <PopupButton
      className={className}
      styles={{background: "transparent"}}
      url="https://calendly.com/subediashish35"
      rootElement={document.getElementById("root")}
      text="Hop on a Call"
    />
  );
};

export default Calendly;
