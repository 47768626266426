import { useEffect, useState } from "react";
import "./assets/css/app.scss";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { AppContextProvider } from "./main/context";
import AboutMe from "./modules/AboutMe";
import Blogs from "./modules/Blogs";
import GetInTouch from "./modules/GetInTouch";
import MainBanner from "./modules/MainBanner";
import Work from "./modules/Work";

function App() {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 3000);
  }, []);

  return (
    <AppContextProvider>
      <div className="App">
        {showLoader ? (
          <div>
            <div className="stars" />
            <div className="stars1" />
            <div className="position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
              <div className="logo-loader d-flex justify-content-center align-items-center">
                A
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Header />
            <div className="position-absolute w-100 h-100">
              <MainBanner />
              <AboutMe />
              <Work />
              <Blogs />
              <GetInTouch />
            </div>
            <Footer />
          </div>
        )}
      </div>
    </AppContextProvider>
  );
}

export default App;
